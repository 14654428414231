import React from "react";
import useDocusaurusContext from "@docusaurus/useDocusaurusContext";
import {
  ClerkProvider,
  SignedIn as ClerkSignedIn,
  SignedOut as ClerkSignedOut,
  SignInButton as ClerkSignInButton,
  UserButton as ClerkUserButton,
} from "@clerk/clerk-react";
import styles from "./pages/index.module.css";

type ProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: ProviderProps) => {
  const { siteConfig } = useDocusaurusContext();
  return (
    <ClerkProvider
      // Todo: Style and enable redirect pages
      //signInForceRedirectUrl="/welcome-back"
      //signUpForceRedirectUrl="/welcome"
      publishableKey={siteConfig.customFields.clerkPubishableKey as string}
    >
      {children}
    </ClerkProvider>
  );
};

export const SignInButton = () => (
  <ClerkSignInButton>
    <button className="button button--secondary button--lg">Sign In</button>
  </ClerkSignInButton>
);

export const SignedIn = ClerkSignedIn;
export const SignedOut = ClerkSignedOut;
export const UserButton = ClerkUserButton;
export { useUser } from "@clerk/clerk-react";
